import React from 'react';
import Header from '../Components/Header';
import LeftSidebar from '../Components/LeftSidebar';

const InformationForParents = () => {
    return (
        <div className="flex flex-col min-h-screen bg-gray-900 text-white">
            <Header />
            <div className="flex flex-grow">
                <LeftSidebar />

                <main className="flex-grow p-8 pt-[90px]">
                    <h1 className="text-3xl font-bold mb-6">Information for Parents and Guardians</h1>
                    <p className="mb-4">
                        Dear Parents and Guardians,
                        At ZinGames, powered by ApplySet Media, we prioritize the online safety of children. We recognize the importance of safeguarding their online experience, and this guide is designed to provide a comprehensive overview of our efforts. For further questions, please don't hesitate to reach out at <b>support@zingames.com.</b>
                    </p>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">What is ZinGames?</h3>
                    <p className="mb-4">
                        ZinGames is a free browser-based gaming platform. We host a diverse array of games, from exciting action and racing games to creative dress-up and strategy games. Our platform supports itself through safe, unobtrusive advertising, allowing users to enjoy our games without any cost.
                    </p>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">Ensuring Your Child's Online Safety</h3>
                    <p className="mb-4">To protect your child while they use our platform, we recommend the following precautions:</p>
                    <ul className="list-disc ml-8 mb-4">
                        <li>Direct younger children to use ZinGames under adult supervision, especially if under the age of 13.</li>
                        <li>Educate your child never to share personal details like home addresses, email addresses, or phone numbers online.</li>
                        <li>Advise your child to choose a nondescript username and secure password, and to keep this password confidential.</li>
                        <li>Teach your child to come to you if they encounter any uncomfortable situations or content online.</li>
                        <li>Make sure your child understands the importance of being truthful about their age when using online platforms.</li>
                    </ul>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">Our Commitment to Child Safety</h3>
                    <p className="mb-4">
                        ZinGames rigorously monitors the content on our platform, ensuring it respects the rights and dignity of all players. We employ filters and moderators to prevent inappropriate language or content from being displayed. Our developers are instructed to adhere strictly to these standards to maintain a safe gaming environment.
                    </p>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">In-Game Purchases and Financial Safety</h3>
                    <p className="mb-4">
                        While every game on ZinGames can be played for free, some may offer optional in-game purchases. These are governed by specific terms and conditions that must be accepted before the purchase is made. ZinGames does not directly facilitate these transactions but provides links to games offering them.
                    </p>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">Reporting Inappropriate Advertisements</h3>
                    <p className="mb-4">
                        We curate our advertisements partners carefully to align with our audience's appropriateness. If you or your child encounter any disturbing ads, please contact us at <b>privacy@zingames.com</b> with details and screenshots of the ad. We will address the issue promptly.
                    </p>
                    <h3 className="text-2xl font-semibold mt-6 mb-4">Personal Data and Privacy</h3>
                    <p className="mb-4">
                        ZinGames collects minimal personal data, prioritizing user privacy and security. Our Privacy Policy provides detailed information about how we handle data collection. If you have concerns about your child's data or believe they may have submitted personal information to us, please contact us at <b>privacy@zingames.com.</b> We take serious steps to verify your identity to protect your child's privacy before accessing any personal data.
                    </p>
                    <p className="mb-4">
                        Our goal at ZinGames is to provide a secure, enjoyable, and educational gaming environment that respects user privacy and promotes responsible gaming practices. We appreciate your cooperation and commitment to keeping our gaming community safe and fun for everyone.
                    </p>
                </main>
            </div>

            <footer className="bg-gray-800 text-white p-4 text-center">
                &copy; 2024 ZinGames. All rights reserved.
            </footer>
        </div>
    );
};

export default InformationForParents;
