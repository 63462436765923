import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import GameList from "../Components/GameList";

const GamePage = () => {
  const location = useLocation();
  const { gameLink, gameDescription } = location.state || { gameLink: "" };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white">
      <Header />
      <GameList gameLink={gameLink} gameDescription={gameDescription} />
      <Footer />
    </div>
  );
};

export default GamePage;
