import React, { useEffect, useRef, useState } from "react";
import LeftSidebar from "./LeftSidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const GameList = ({ gameLink, gameName, gameImage, gameDescription }) => {
  const navigate = useNavigate();
  const [gamesDetails, setGamesDetails] = useState([]);
  const [selectedGameLink, setSelectedGameLink] = useState(
    gameLink || "https://play.gamepix.com/flamit/embed?sid=A88E5"
  );
  const [selectedGameDescription, setselectedGameDescription] =
    useState(gameDescription);
  const iframeRef = useRef(null);

  // Fisher-Yates Shuffle Algorithm
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // swap elements
    }
    return array;
  }

  // Function to get a random number between 1 and 60
  function getRandomPageNumber() {
    return Math.floor(Math.random() * 60) + 1; // Returns a random integer between 1 and 60
  }

  useEffect(() => {
    const fetchData = async () => {
      const randomPage = getRandomPageNumber(); // Random page between 1 and 60
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}&page=${randomPage}`
        );

        // Shuffle the response data before setting the state
        const shuffledGames = shuffleArray(response.data.items);

        setGamesDetails(shuffledGames);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleGameClick = (link, namespace, description) => {
    navigate(`/game/${namespace}`);
    setSelectedGameLink(link);
    setselectedGameDescription(description);
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // Logic for Last responsive part
  const [gamesPerRow, setGamesPerRow] = useState(5); // Default value for larger screens

  useEffect(() => {
    // Function to calculate how many games to show per row based on the screen size
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth < 640) {
        setGamesPerRow(2); // Mobile: 2 games per row
      } else if (screenWidth >= 640 && screenWidth < 768) {
        setGamesPerRow(3); // Small screens: 3 games per row
      } else if (screenWidth >= 768 && screenWidth < 1024) {
        setGamesPerRow(4); // Tablets: 4 games per row
      } else {
        setGamesPerRow(5); // Laptops and larger: 5 games per row
      }
    };

    // Run the function once on mount
    handleResize();

    // Attach event listener to track window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to chunk the gamesDetails array based on gamesPerRow and filter out incomplete rows
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      const chunk = arr.slice(i, i + size);
      if (chunk.length === size) {
        result.push(chunk); // Only add complete rows
      }
    }
    return result;
  };

  // Get the chunked array of games based on the current screen size
  const visibleGames = chunkArray(gamesDetails, gamesPerRow);

  return (
    <div className="container mx-auto flex-grow flex mt-[70px]">
      <LeftSidebar />
      {/* Main Content Area */}
      <main className="flex-grow p-5">
        {/* Game Layout: Two Rectangles with 70% and 30% Width */}
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4">
          {/* First Rectangle (70% width on desktop) */}
          <div className="w-full md:w-[70%] h-full rounded-lg shadow-lg justify-center items-center relative">
            <iframe
              ref={iframeRef}
              src={selectedGameLink}
              title="Main Game"
              className="w-full h-[300px] md:h-[560px] rounded-lg"
            />
            <div className="flex justify-center items-center">
              <img
                src={gameImage}
                alt={gameName}
                className="h-28 w-11/12 object-cover rounded-lg mt-4 md:mt-8"
              />
            </div>
            <div className="flex justify-around mt-4 space-x-2 md:space-x-4">
              {gamesDetails ? (
                gamesDetails.slice(0, 3).map((game, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 2
                        ? "w-full sm:w-[50%] md:w-[50%]" // full width on small screens, 50% on medium and large
                        : "w-full sm:w-[50%] md:w-[25%]" // full width on small screens, 50% on small, 25% on medium and large
                    } h-28 md:h-36 bg-gray-600 rounded-lg flex justify-center items-center`}
                    onClick={() =>
                      handleGameClick(
                        game?.url,
                        game?.namespace,
                        game?.description
                      )
                    }
                  >
                    <img
                      src={game?.banner_image}
                      alt={game?.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))
              ) : (
                <div className="text-white">Loading...</div>
              )}
            </div>

            <div className="flex justify-around mt-4 space-x-2 md:space-x-4">
              {gamesDetails ? (
                gamesDetails.slice(3, 6).map((game, index) => (
                  <div
                    key={index}
                    className={`${
                      index === 0
                        ? "w-full sm:w-[50%] md:w-[50%]" // full width on small screens, 50% on medium and large
                        : "w-full sm:w-[50%] md:w-[25%]" // full width on small screens, 50% on small, 25% on medium and large
                    } h-28 md:h-36 bg-gray-600 rounded-lg flex justify-center items-center`}
                    onClick={() =>
                      handleGameClick(
                        game?.url,
                        game?.namespace,
                        game?.description
                      )
                    }
                  >
                    <img
                      src={game?.banner_image}
                      alt={game?.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))
              ) : (
                <div className="text-white">Loading...</div>
              )}
            </div>

            <div className="mt-4">
              <div className="w-full h-[300px] md:h-[570px] bg-gray-600 rounded-lg p-6 text-white flex flex-col justify-center items-center">
                <div className="w-11/12 h-full rounded-lg p-6 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-700">
                  <div className="max-h-full text-sm md:text-base lg:text-lg">
                    {selectedGameDescription}
                  </div>
                </div>
                <div className="mt-4 w-full flex justify-center items-center">
                  <div className="w-11/12 h-20 md:h-30 bg-gray-400 rounded-lg flex justify-center items-center">
                    <p className="text-sm md:text-lg lg:text-xl font-semibold text-gray-800">
                      Sponsored by: Zin Gamers – Power up your game today!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Rectangle (30% width on desktop) */}
          <div className="w-full md:w-[30%] flex flex-col space-y-4 mt-4 md:mt-0">
            {/* First Row: 2 Games */}
            <div className="flex space-x-4">
              {gamesDetails ? (
                gamesDetails.slice(6, 8).map((game, index) => (
                  <div
                    key={index}
                    className="relative w-1/2 h-36 md:h-48 bg-gray-700 rounded-lg shadow-lg overflow-hidden group"
                    onClick={() =>
                      handleGameClick(
                        game?.url,
                        game?.namespace,
                        game?.description
                      )
                    }
                  >
                    <img
                      src={game?.banner_image}
                      alt={game?.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <h3 className="text-white text-sm md:text-xl font-semibold">
                        {game?.title}
                      </h3>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>

            {/* Second Row: 1 Game */}
            <div className="w-full h-[300px] md:h-[570px] bg-gray-700 rounded-lg shadow-lg overflow-hidden">
              {gamesDetails ? (
                <>
                  <div
                    className="block w-full h-1/2 p-2 cursor-pointer"
                    onClick={() => {
                      handleGameClick(
                        gamesDetails[9]?.url,
                        gamesDetails[9]?.namespace,
                        gamesDetails[9]?.description
                      );
                    }}
                  >
                    <img
                      src={gamesDetails[9]?.banner_image}
                      alt={gamesDetails[9]?.title}
                      className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                  </div>
                  <div
                    className="block w-full h-1/2 p-2 cursor-pointer"
                    onClick={() => {
                      handleGameClick(
                        gamesDetails[10]?.url,
                        gamesDetails[10]?.namespace,
                        gamesDetails[10]?.description
                      );
                    }}
                  >
                    <img
                      src={gamesDetails[10]?.banner_image}
                      alt={gamesDetails[10]?.title}
                      className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                  </div>
                </>
              ) : (
                <div className="w-full h-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>

            {/* Third Row: 2 Games */}
            <div className="flex space-x-4">
              {gamesDetails ? (
                gamesDetails.slice(11, 13).map((game, index) => (
                  <div
                    key={index}
                    className="relative w-1/2 h-36 md:h-48 bg-gray-700 rounded-lg shadow-lg overflow-hidden group"
                    onClick={() =>
                      handleGameClick(
                        game?.url,
                        game?.namespace,
                        game?.description
                      )
                    }
                  >
                    <img
                      src={game?.banner_image}
                      alt={game?.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <h3 className="text-white text-sm md:text-xl font-semibold">
                        {game?.title}
                      </h3>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>

            {/* Fourth Row: 1 Game */}
            <div className="w-full h-36 md:h-48 bg-gray-400 rounded-lg flex justify-center items-center p-4 text-white">
              {gamesDetails ? (
                <div
                  className="w-full h-full flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    handleGameClick(
                      gamesDetails[14]?.url,
                      gamesDetails[14]?.namespace,
                      gamesDetails[14]?.description
                    );
                  }}
                >
                  <div className="w-full h-full relative">
                    <img
                      src={gamesDetails[14]?.banner_image}
                      alt={gamesDetails[14]?.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300">
                      <span className="text-sm md:text-xl font-bold">
                        {gamesDetails[14]?.title}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>

            {/* Fifth Row: 2 Games */}
            <div className="flex space-x-4">
              {gamesDetails ? (
                gamesDetails.slice(15, 17).map((game, index) => (
                  <div
                    key={index}
                    className="relative w-1/2 h-36 md:h-48 bg-gray-700 rounded-lg shadow-lg overflow-hidden group"
                    onClick={() =>
                      handleGameClick(
                        game?.url,
                        game?.namespace,
                        game?.description
                      )
                    }
                  >
                    <img
                      src={game?.banner_image}
                      alt={game?.title}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <h3 className="text-white text-sm md:text-xl font-semibold">
                        {game?.title}
                      </h3>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>

            {/* Sixth Row: 1 Game */}
            <div className="w-full h-36 md:h-48 bg-gray-400 rounded-lg flex justify-center items-center p-4 text-white">
              {gamesDetails ? (
                <div
                  className="w-full h-full flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    handleGameClick(
                      gamesDetails[18]?.url,
                      gamesDetails[18]?.namespace,
                      gamesDetails[18]?.description
                    );
                  }}
                >
                  <div className="w-full h-full relative">
                    <img
                      src={gamesDetails[18]?.banner_image}
                      alt={gamesDetails[18]?.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300">
                      <span className="text-sm md:text-xl font-bold">
                        {gamesDetails[18]?.title}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex justify-center items-center text-white">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Last All Game section Start */}

        <div className="mt-8 space-y-6">
          {visibleGames.map((gameRow, rowIndex) => (
            <div
              key={rowIndex}
              className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4"
            >
              {gameRow.map((game, index) => (
                <div
                  key={index}
                  className="relative cursor-pointer"
                  onClick={() => handleGameClick(game?.url, game?.namespace)}
                >
                  <img
                    src={game?.banner_image}
                    alt={game?.title}
                    className="w-full h-36 object-cover rounded-lg"
                  />
                  <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300">
                    <span className="text-sm font-bold text-white">
                      {game?.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Last All Game section End */}
      </main>
    </div>
  );
};

export default GameList;
