import React from 'react';
import LeftSidebar from '../Components/LeftSidebar';
import Header from '../Components/Header';

const TermsOfUse = () => {
    return (
        <div className="bg-gray-900 min-h-screen flex flex-col">
            <Header />
            <div className="flex flex-1">
                <LeftSidebar />
                <main className="flex-1 p-8 pt-[90px]">
                    <h1 className="text-4xl font-bold mb-6 text-gray-100">Terms of Use</h1>
                    <div className="text-gray-300 space-y-4">
                        <p>Welcome to ZinGames! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern ZinGames' relationship with you in relation to this website.</p>

                        <p>We, ApplySet Media <b>ZinGames</b> a company established under the Indian law, welcome you to our online platform accessible at <a href="https://zingames.com" className="text-blue-500">https://zingames.com</a> or <a href="http://zingames.com" className="text-blue-500">http://zingames.com</a>. Here, we provide access to a variety of virtual environments, games, and other engaging content, along with downloadable software or applications suitable for personal computers, tablets, mobile devices, or phones. As a user of the ZinGames Website, referred to as <b>"Website Visitors,"</b> you are bound by the terms and conditions outlined in this document, known as the <b>"Terms of Use."</b></p>

                        <p>Before proceeding to explore or utilize any part of the ZinGames Website, we urge you to carefully review these Terms of Use. By accessing or using any portion of this ZinGames Website, you indicate that you have read, comprehended, and agreed to abide by these Terms of Use, subject to any future amendments made by ZinGames. Should you disagree with any aspect of these Terms of Use or find them unsatisfactory, your sole recourse is to refrain from accessing or using any part of this ZinGames Website.</p>

                        <p>ZinGames reserves the right to modify and update these Terms of Use at its discretion, with any revisions being communicated by posting the amended Terms of Use on the ZinGames Website. Your continued usage of the ZinGames Website following such changes indicates your acceptance and adherence to the revised Terms of Use. Failure to comply with these Terms of Use or dissatisfaction with the ZinGames Website warrants discontinuation of use as the exclusive remedy.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">1. Responsible Use of the ZinGames Platform</h2>
                        <ol className="list-decimal list-inside space-y-2">
                            <li><b>Commercial Use Restrictions:</b> Users are prohibited from reproducing, distributing, or exploiting any part of the ZinGames Website for commercial purposes without explicit written permission from ZinGames.</li>
                            <li><b>User-Generated Content Responsibility:</b> Content submitted to public areas of the ZinGames Website is the responsibility of the submitter. ZinGames does not endorse or guarantee the accuracy of such content. Users warrant that any material uploaded is either their own, in the public domain, or free of proprietary restrictions. By submitting content, users grant ZinGames the right to use it in various capacities.</li>
                            <li><b>Moderation and Termination:</b> ZinGames reserves the right to remove any material deemed inconsistent with these terms, monitor communications, and terminate user access to the ZinGames Website. However, ZinGames is not liable for content provided by third parties.</li>
                            <li><b>Consequences of Non-Compliance:</b> Failure to adhere to these provisions may result in termination of access to the ZinGames Website and potential civil or criminal liabilities.</li>
                            <li><b>Compliance with Legal Standards:</b> Your use of the ZinGames Website must adhere to all applicable laws and regulations. Specifically, you agree not to:</li>
                            <ul className="list-disc list-inside space-y-1 pl-4">
                                <li><b>Advertising:</b> Post advertisements or solicitations of business.</li>
                                <li><b>Unauthorized Data Collection:</b> Collect information about others without their consent.</li>
                                <li><b>Unauthorized Access:</b> Intercept or attempt to intercept electronic mail not intended for you.</li>
                                <li><b>Misrepresentation:</b> Falsely represent an affiliation with any individual or organization.</li>
                                <li><b>Interference:</b> Restrict or inhibit others' use of the ZinGames Website.</li>
                                <li><b>Malicious Content:</b> Upload or transmit files containing viruses or corrupted data.</li>
                                <li><b>Privacy Violation:</b> Solicit, provide, or exchange personal information without consent.</li>
                                <li><b>Illegal Distribution:</b> Download or transmit software or files that cannot be legally distributed through the ZinGames Website.</li>
                                <li><b>Content Restrictions:</b> Upload or transmit any defamatory, indecent, obscene, harassing, violent, or otherwise objectionable material. This includes content that may be protected by copyright, without obtaining the necessary permissions.</li>
                                <li><b>Spam:</b> Post spam or engage in similar activities like transmitting chain letters.</li>
                                <li><b>Harassment:</b> Engage in stalking, phishing, or harassment of other users.</li>
                                <li><b>Violation of Rights:</b> Utilize the ZinGames Website to infringe upon the legal rights of others or to breach laws in any jurisdiction.</li>
                                <li><b>Conduct Limitation:</b> Conduct any activity that restricts or inhibits others' use or enjoyment of the ZinGames Website, or that may harm ZinGames or Website Visitors, as determined by ZinGames.</li>
                            </ul>
                        </ol>

                        <h2 className="text-2xl font-semibold text-gray-100">2. Safeguarding Personal Information</h2>
                        <p>At ZinGames, we prioritize the protection of your privacy. We understand the importance of safeguarding your personal information. Our commitment to privacy is outlined in detail in our Website Privacy Policy.</p>
                        
                        <h2 className="text-2xl font-semibold text-gray-100">3. Age Requirement</h2>
                        <p>To access and enjoy the offerings of the ZinGames Website, users must certify that they are at least 13 years old. Individuals under the age of 13 are encouraged to visit our ZinGames Kids website (the “kids.zingames.com Website”), designed specifically for younger audiences.</p>
                        
                        <h2 className="text-2xl font-semibold text-gray-100">4. Game Embedding Policy</h2>
                        <p>At ZinGames, we offer the opportunity for other websites to embed portions of our gaming platform, ZinGames, through an iframe mechanism. However, this privilege comes with certain responsibilities and restrictions:</p>
                        <ul className="list-disc list-inside space-y-1 pl-4">
                            <li><b>Embedding Process:</b> ZinGames allows other websites to embed portions of our platform through an iframe, provided that the website includes an "embed" button to retrieve the iframe source code.</li>
                            <li><b>Advertising:</b> When games are embedded, they may contain in-game advertisements. These advertisements are an integral part of the gaming experience.</li>
                            <li><b>Qualification Withdrawal:</b> ApplySet Media reserves the right to withdraw qualification for embedding at any time. We may also block sites from embedding games at our discretion, without prior warning or the need to provide justification.</li>
                            <li><b>Prohibited Actions:</b> It is strictly forbidden to engage in certain actions such as reproducing, modifying, or interfering with the embedded content in ways that violate ZinGames' terms.</li>
                            <li><b>Discontinuation:</b> ApplySet Media reserves the right to discontinue an iframed portion of ZinGames at any time.</li>
                        </ul>

                        <h2 className="text-2xl font-semibold text-gray-100">5. User Profiles and Interactive Platforms</h2>
                        <p>At ZinGames, we may introduce functionality allowing site visitors to share materials through the ZinGames platform. This includes profile pages and interactive services such as forums, message boards, chatting, commenting, and messaging features. However, it is important to adhere to our Rules of Conduct outlined in Section 1.</p>
                        <p><b>Submission Responsibility:</b> Users are responsible for any materials they share on the ZinGames platform. ApplySet Media does not have control over, and cannot be held liable for, any use or misuse of these Submissions by third parties.</p>
                        <p><b>Public Disclosure:</b> If you choose to make any personally identifiable or other information publicly available on ZinGames, you do so at your own risk. Please refer to Section 1 (V) of our Privacy Policy for details regarding the collection of personal information.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">6. Compatibility with Your Devices</h2>
                        <p>It's essential to be aware that when utilizing downloadable applications from ApplySet Media, updates to your device's systems or firmware could potentially</p>
                        <h2 className="text-2xl font-semibold text-gray-100">7. Termination of Access</h2>
                        <p>ZinGames reserves the right to terminate or suspend your access to the ZinGames Website, without prior notice or liability, if you violate these Terms of Use or if we believe that such action is necessary to protect the integrity and security of the website.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">8. Severability</h2>
                        <p>If any provision of these Terms of Use is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will remain in full force and effect. The invalid or unenforceable provision will be deemed modified to the minimum extent necessary to make it valid and enforceable.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">9. Entire Agreement</h2>
                        <p>These Terms of Use constitute the entire agreement between you and ZinGames regarding the use of the ZinGames Website and supersede all prior agreements and understandings, whether written or oral, relating to the subject matter hereof.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">10. Amendments</h2>
                        <p>ZinGames reserves the right to update or modify these Terms of Use at any time. Any changes will be effective immediately upon posting on the ZinGames Website. It is your responsibility to review these Terms of Use periodically to stay informed of any updates.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">11. User Feedback</h2>
                        <p>We welcome feedback from our users to improve the ZinGames Website and its features. However, any feedback or suggestions you provide shall become the exclusive property of ZinGames, and we may use such feedback without any obligation to you.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">12. Third-Party Links</h2>
                        <p>The ZinGames Website may contain links to third-party websites or services. These links are provided for your convenience and do not imply endorsement or responsibility for the content or practices of those third parties. You access such sites at your own risk.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">13. No Waiver</h2>
                        <p>The failure of ZinGames to enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision. Any waiver of any provision of these Terms of Use will be effective only if in writing and signed by a duly authorized representative of ZinGames.</p>

                        <h2 className="text-2xl font-semibold text-gray-100">14. Contact Information</h2>
                        <p>If you have any questions, concerns, or complaints regarding these Terms of Use or the ZinGames Website, please reach out to us at:</p>
                        <p className="text-blue-500">Email: <a href="mailto:support@zingames.com">support@zingames.com</a></p>
                        <p className="text-blue-500">Address: 88, Gokul park, Utran, Chorasi, Surat- 394105, Gujarat, India</p>

                        <p className="mt-4">Thank you for using ZinGames. We hope you enjoy our platform!</p>
                    </div>
                </main>
            </div>
                <footer className="bg-gray-800 text-white p-4 text-center">
                &copy; 2024 ZinGames. All rights reserved.
            </footer>
        </div>
    );
};

export default TermsOfUse;
