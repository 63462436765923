import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ControllerIcon from "../Assets/PS Controller.svg";
import HamburgerIcon from "../Assets/Hamburger.svg";
import BallIcon from "../Assets/CatagoryIcon/Ball.svg";
import AdventureIcon from "../Assets/CatagoryIcon/Adventure.svg";
import FightingIcon from "../Assets/CatagoryIcon/Fighting.svg";
import ActionIcon from "../Assets/CatagoryIcon/Action.svg";
import SportsIcon from "../Assets/CatagoryIcon/Sports.svg";
import StickmanIcon from "../Assets/CatagoryIcon/Stickman.svg";
import TriviaIcon from "../Assets/CatagoryIcon/Trivia.svg";
import PuzzleIcon from "../Assets/CatagoryIcon/Puzzle.svg";
import EducationalIcon from "../Assets/CatagoryIcon/Educational.svg";
import KidsIcon from "../Assets/CatagoryIcon/Kids.svg";
import DrawingIcon from "../Assets/CatagoryIcon/Drawing.svg";
import ArcadeIcon from "../Assets/CatagoryIcon/Arcade.svg";
import AnimalIcon from "../Assets/CatagoryIcon/Animal.svg";
import ParkourIcon from "../Assets/CatagoryIcon/Parkour.svg";
import RobotsIcon from "../Assets/CatagoryIcon/Robots.svg";
import RacingIcon from "../Assets/CatagoryIcon/Racing.svg";
import FunIcon from "../Assets/CatagoryIcon/Fun.svg";
import StrategyIcon from "../Assets/CatagoryIcon/Strategy.svg";
import RetroIcon from "../Assets/CatagoryIcon/Retro.svg";
import ChristmasIcon from "../Assets/CatagoryIcon/Christmas.svg";
import MemoryIcon from "../Assets/CatagoryIcon/Memory.svg";
import IdleIcon from "../Assets/CatagoryIcon/Idle.svg";
import Match3Icon from "../Assets/CatagoryIcon/Match-3.svg";
import ShooterIcon from "../Assets/CatagoryIcon/Shooter.svg";
import SnakeIcon from "../Assets/CatagoryIcon/Snake.svg";
import TanksIcon from "../Assets/CatagoryIcon/Tanks.svg";
import BoardIcon from "../Assets/CatagoryIcon/Board.svg";
import CasualIcon from "../Assets/CatagoryIcon/Casual.svg";
import MonsterIcon from "../Assets/CatagoryIcon/Monster.svg";
import CardIcon from "../Assets/CatagoryIcon/Card.svg";
import MathIcon from "../Assets/CatagoryIcon/Math.svg";
import MobileIcon from "../Assets/CatagoryIcon/Mobile.svg";

const LeftSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const hamburgerRef = useRef(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const categories = [
    { name: "Ball", icon: BallIcon },
    { name: "Adventure", icon: AdventureIcon },
    { name: "Fighting", icon: FightingIcon },
    { name: "Action", icon: ActionIcon },
    { name: "Sports", icon: SportsIcon },
    { name: "Stickman", icon: StickmanIcon },
    { name: "Trivia", icon: TriviaIcon },
    { name: "Puzzle", icon: PuzzleIcon },
    { name: "Educational", icon: EducationalIcon },
    { name: "Kids", icon: KidsIcon },
    { name: "Drawing", icon: DrawingIcon },
    { name: "Arcade", icon: ArcadeIcon },
    { name: "Animal", icon: AnimalIcon },
    { name: "Parkour", icon: ParkourIcon },
    { name: "Robots", icon: RobotsIcon },
    { name: "Racing", icon: RacingIcon },
    { name: "Fun", icon: FunIcon },
    { name: "Strategy", icon: StrategyIcon },
    { name: "Retro", icon: RetroIcon },
    { name: "Christmas", icon: ChristmasIcon },
    { name: "Memory", icon: MemoryIcon },
    { name: "Idle", icon: IdleIcon },
    { name: "Match-3", icon: Match3Icon },
    { name: "Shooter", icon: ShooterIcon },
    { name: "Snake", icon: SnakeIcon },
    { name: "Tanks", icon: TanksIcon },
    { name: "Board", icon: BoardIcon },
    { name: "Casual", icon: CasualIcon },
    { name: "Monster", icon: MonsterIcon },
    { name: "Card", icon: CardIcon },
    { name: "Math", icon: MathIcon },
    { name: "Mobile", icon: MobileIcon },
  ];

  const sidebarLinks = [
    { name: "About Us", path: "/about-us" },
    { name: "Contact Us", path: "/contact-us" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Terms of Use", path: "/terms-of-use" },
    { name: "Cookie Policy", path: "/cookie-policy" },
    { name: "FAQs", path: "/faqs" },
    {
      name: "Information for Parents and Guardians",
      path: "/information-for-parents",
    },
  ];

  const handleCategoryClick = (categoryName) => {
    const formattedCategory = categoryName.toLowerCase().replace(/ /g, "-");
    navigate(`/category/${formattedCategory}`, {
      state: { category: categoryName },
    });
  };

  return (
    <>
      <button
        onClick={toggleSidebar}
        ref={hamburgerRef}
        style={{
          position: "fixed",
          top: "20px",
          left: "20px",
          zIndex: 1001,
          padding: "8px",
          backgroundColor: "#2d3748",
          color: "#edf2f7",
          border: "none",
          cursor: "pointer",
          width: "40px",
          height: "24px",
        }}
      >
        <img
          src={HamburgerIcon}
          alt="Menu"
          style={{ width: "32px", height: "32px" }}
        />
      </button>

      <div
        ref={sidebarRef}
        style={{
          backgroundColor: "#2d3748",
          width: "230px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          marginTop: "80px",
          paddingTop: "10px",
          paddingLeft: "10px",
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          overflowY: "auto",
          transition: "transform 0.3s ease-in-out",
          transform: isOpen ? "translateX(0)" : "translateX(-100%)",
          zIndex: 1000,
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        <style>
          {`
            ::-webkit-scrollbar {
              display: none;
            }
          `}
        </style>

        {/* Render categories */}
        {categories.map((category, index) => (
          <div
            key={index}
            onClick={() => handleCategoryClick(category.name)}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#edf2f7",
              cursor: "pointer",
            }}
          >
            <img
              src={category.icon}
              alt={`${category.name} Icon`}
              style={{ width: "32px", height: "32px" }}
            />
            <span>{category.name}</span>
          </div>
        ))}

        {/* Divider line between categories and sidebar links */}
        <hr
          style={{
            border: "none",
            borderTop: "1px solid #4a5568",
            margin: "10px 0",
          }}
        />

        {/* Render sidebar links */}
        {sidebarLinks.map((link, index) => (
          <Link
            to={link.path}
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#a0aec0",
              textDecoration: "none",
              marginBottom: index === sidebarLinks.length - 1 ? "90px" : "0",
            }}
          >
            {link.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default LeftSidebar;
