import React from 'react';
import LeftSidebar from '../Components/LeftSidebar';
import Header from '../Components/Header';

const ContactUs = () => {
    return (
        <div className="bg-gray-900 min-h-screen flex flex-col">
            <Header />
            <div className="flex flex-1">
                <LeftSidebar />
                <main className="flex-1 p-8 pt-[90px]">
                    <h1 className="text-4xl font-bold mb-6 text-gray-100">Contact Us</h1>
                    <p className="mb-6 text-gray-300">At ZinGames, we value your feedback and inquiries. We encourage you to reach out to us using the information below:</p>
                    <div className="text-gray-300">
                        <p className="font-semibold mb-2">Email:</p>
                        <ul className="list-disc list-inside mb-6 pl-5">
                            <li><strong>General Inquiries:</strong> hello@zingames.com</li>
                            <li><strong>Privacy Concerns:</strong> privacy@zingames.com</li>
                            <li><strong>Support:</strong> support@zingames.com</li>
                            <li><strong>Developer Inquiries:</strong> developers@zingames.com</li>
                        </ul>
                        <p className="font-semibold mb-2">Mailing Address:</p>
                        <p className="mb-6">Applyset Media, 88 Gokul Park, Utran, Chorasi Surat-394105, Gujarat, India</p>
                        <p className="font-semibold">Social Media:</p>
                        <p>We are currently building our social media presence. Stay tuned for updates!</p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ContactUs;
