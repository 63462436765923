import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import GamePage from "./Pages/GamePage";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import CookiePolicy from "./Pages/CookiePolicy";
import FAQs from "./Pages/FAQs";
import InformationForParents from "./Pages/InformationForParents";
import CategoryPage from "./Pages/CatagoryPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/game/:gameName" element={<GamePage />} />
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/information-for-parents" element={<InformationForParents />} />
      </Routes>
    </Router>
  );
};

export default App;
