import React from "react";
import Header from "../Components/Header";
import LeftSidebar from "../Components/LeftSidebar";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-900 text-gray-100">
        <Header />
        <div className="flex flex-1">
          <LeftSidebar />
          <main className="flex-1 p-6 mt-[70px]">
            {" "}
            <div class="container">
              <h1 className="font-bold text-4xl pb-2">Privacy Policy</h1>
              <p>
                Zingames, operated by Applyset Media, a limited liability
                company under the Indian law, registered at Surat, Gujarat,
                India (hereinafter referred to as{" "}
                <b>“Zingames”, ”Applyset Media”, “we”, “us”, or “our”</b>),
                operates the website zingames.com (hereinafter referred to as{" "}
                <b>“Site” or “Website”</b>). This Privacy Policy applies to all
                services and products offered by Zingames and Zingames.com.
                Please note that Zingames is not responsible for the privacy
                practices of other websites and sources.
              </p>
              <p>
                Zingames is the data controller for the purposes of this Privacy
                Policy. By using our Site, you agree to this Privacy Policy and
                confirm that you are of legal age to agree to its terms.
              </p>
              <p>
                We may update this Privacy Policy to reflect new technologies,
                practices, or legal requirements. Please check here often for
                updates. your continued use of the Site will be considered as
                your consent to the revised terms. We respect the privacy of all
                users and ensure that the personal information you provide is
                treated confidentially. We understand the trust you place in us
                and are committed to protecting your privacy. This page explains
                what information we collect when you use our Site, why we
                collect it, and how it enhances your user experience.
              </p>
              <h3 className="font-bold text-2xl pt-1">1. Data Collection and Utilization</h3>
              <p>
                All functionalities on the Zingames Website are accessible
                without requiring registration. We do not collect or process
                personal contact information such as your name, email address,
                or phone number unless you actively reach out to us.
                Additionally, our game developers are typically required to
                remove all outgoing links, branding, and advertisements (such as
                splash screens, social links, and app store links) from their
                games.
              </p>
              <p>
                However, Zingames does gather certain electronic data, which we
                may process in various ways. Below is an overview of our data
                processing practices and detailed information about specific
                contexts.
              </p>
              <h4 className="font-bold text-xl pt-1">General Data Processing Purposes</h4>
              <p>We may process your data for the following reasons:</p>
              <p>- Maintenance, administration, and security of our networks</p>
              <p>- Internal controls and business operations</p>
              <p>- Analyzing and enhancing our products</p>
              <p>- Addressing requests, complaints, and disputes</p>
              <p>- Exercising and defending our legal rights</p>
              <p>
                - Complying with legal obligations and requests from government
                authorities
              </p>
              <h4 className="font-bold text-xl pt-1">General Data Processing Grounds</h4>
              <p>
                We may process your data based on legal obligations, meaning we
                handle your data as required by law, such as for statutory
                retention periods
              </p>
              <p>
                <b>Legitimate Interest: </b>Sometimes, we process your data
                based on our legitimate interests. This means we balance our
                interests against your privacy rights, and if our interests
                prevail, we proceed with the processing. If you want more
                information about this, you can contact us directly using the
                contact details provided below.
              </p>
              <p>
                <b>Minors and Other Protected Individuals: </b>The Zingames
                Website is not intended for individuals under 13 years old or
                those under legal guardianship or supervision. If we do focus on
                such individuals, Zingames will take extra precautions to ensure
                their privacy and security.
              </p>
              <h4 className="font-bold text-xl pt-1">Specific Data Processing Scenarios</h4>
              <b>General Website Usage</b>
              <p>
                We process your data to provide, maintain, and improve the
                Zingames Website, our apps, and for our social media activities.
                For more specific information on the cookies and similar
                technologies used, please refer to our Cookie Statement.
              </p>
              <b>Visitors to Our Website</b>
              <p>
                When you visit the Zingames Websites, your data may be processed
                for the following purposes:
              </p>
              <p>
                - Functioning of the Zingames Website (including remembering
                your settings and consent choices)
              </p>
              <p>
                - Advertising and marketing to you, including targeted
                advertising and presenting relevant advertisements
              </p>
              <p>
                - Displaying privacy information relevant to your region (the
                specific data processed depends on the region from which you
                access the Zingames Website)
              </p>
              <b>Data Collected During Visits</b>
              <p>
                When you visit the Zingames Website, we may automatically
                collect and process the following information about you:
              </p>
              <p>- IP address</p>
              <p>- Device ID</p>
              <p>- Browser type</p>
              <p>- Global geographic location (e.g., country or city level)</p>
              <p>
                - Advertisements displayed to you and your interactions with
                them (e.g., whether you clicked on an advertisement)
              </p>
              <p>
                - Other technical information (e.g., interaction between your
                device and the Zingames Website, web pages visited, links
                clicked, and log data)
              </p>
              <b>Sensitive Information</b>
              <p>
                In the context of the Zingames Website, we generally do not
                process sensitive information such as health-related data. If we
                decide to process sensitive information, you will be informed
                separately, and we will request explicit consent where
                necessary.
              </p>
              <h4 className="font-bold text-xl pt-1">Legal Grounds for Data Processing</h4>
              <b>Consent</b>
              <p>
                In some cases, we base the processing of your data on your
                (explicit) consent, depending on local legal requirements.
                Consent may be expressed or implied from your actions and the
                context. In other situations, you can opt out of the processing
                of personal data, such as for certain cookies and similar
                technologies. For further information, please refer to our
                Cookie Statement.
              </p>
              <b>Legitimate Interest</b>
              <p>
                We have a legitimate interest in offering and using the Zingames
                Website, apps, and social media, and pursuing the other
                processing purposes listed above.
              </p>
              <b>Social Media Platforms and Advertising Platforms</b>
              <p>
                Zingames uses various social media platforms for recruitment,
                selection, and marketing purposes. While Zingames is not
                responsible for the management of these platforms, it is
                responsible for content shared via these platforms. For more
                information on how your data is used by social media platforms,
                refer to the privacy policies of the respective platforms. Below
                are links to the privacy policies of various social media
                providers we might use:
              </p>
              <p>
                - [Facebook Privacy Policy](https://www.facebook.com/policy.php)
              </p>
              <p>- [Twitter Privacy Policy](https://twitter.com/en/privacy)</p>
              <p>
                - [Instagram Privacy
                Policy](https://help.instagram.com/519522125107875)
              </p>
              <p>
                - [YouTube Privacy
                Policy](https://policies.google.com/privacy?hl=en)
              </p>
              <p>
                - [LinkedIn Privacy
                Policy](https://www.linkedin.com/legal/privacy-policy)
              </p>
              <p>
                - [Google Privacy Policy](https://policies.google.com/privacy)
              </p>
              <p>
                - [Reddit Privacy
                Policy](https://www.redditinc.com/policies/privacy-policy)
              </p>
              <p>
                - [TikTok Privacy
                Policy](https://www.tiktok.com/legal/privacy-policy)
              </p>

              <h4 className="font-bold text-xl pt-1">Contacting Customer Support</h4>
              <p>
                <b>Individuals Involved: </b>People who send an email to
                customer support, for example via
                <b>hello@zingames.com</b> or <b>privacy@zingames.com.</b>
              </p>
              <b>Purpose of Data Processing</b>
              <p>
                When you contact Zingames customer support or reach out to us in
                any other manner, we will process your data for the following
                purposes:
              </p>
              <p>- To provide you with support;</p>
              <p>- To respond to any inquiries you may have.</p>
              <b>Data Processed</b>
              <p>
                When you contact Zingames customer support or otherwise reach
                out to us, we will process the following information about you:
              </p>
              <p>- Email address;</p>
              <p>- Name (if provided);</p>
              <p>
                - The content of your message and related metadata that you
                share with us; as well as any information and related metadata
                we may share with you in response.
              </p>
              <b>Legal Processing’s</b>
              <p>
                We generally base the processing of your data in the context of
                Zingames customer support on the ground of legitimate interest.
                It is in our legitimate interest to provide b support and answer
                any questions you may have.
              </p>
              <h3 className="font-bold text-2xl pt-1">2. Methods of Data Collection</h3>
              <p>We gather data through various means:</p>
              <ul>
                <li>
                  <b>Data You Provide:</b>Information you directly share with
                  us, such as in messages or forms you fill out.
                </li>
                <li>
                  <b>Internal Sources: </b>Data retrieved from other Zingames
                  systems, including our CRM for game developers.
                </li>
                <li>
                  <b>Third-Party Sources: </b>Information obtained from external
                  entities, such as advertising partners. More details can be
                  found in our Cookie Statement.
                </li>
                <li>
                  <b>Automatic Collection: </b>Data collected automatically
                  through cookies and similar technologies, including usage data
                  when your device connects to our web servers.
                </li>
                <li>
                  <b>Inferred Data: </b>Information deduced from existing data,
                  such as your language preferences.
                </li>
              </ul>
              <p>
                Generally, you are not required to provide any personal
                information to us. However, choosing not to provide certain data
                may affect the functionality of the Site. If providing specific
                personal data is a legal or contractual requirement or necessary
                for an agreement with us, we will inform you in advance.
              </p>
              <h3 className="font-bold text-2xl pt-1">3. Data Sharing Policy</h3>
              <p>
                At Zingames, we only disclose your data to third parties under
                specific conditions:
              </p>
              <p>
                - It is essential for the delivery of a service or the
                involvement of a third party.
              </p>
              <p>
                - Authorized personnel within the third party have
                confidentiality obligations.
              </p>
              <p>
                - The third party complies with applicable data protection
                regulations.
              </p>

              <h3 className="font-bold text-2xl pt-1">Specific Circumstances for Data Sharing</h3>
              <p>
                We may share your data on a need-to-know basis with the
                following entities:
              </p>
              <p>
                <b>Authorized Zingames Employees: </b>Employees who are directly
                involved in processing activities and require access to perform
                their duties.
              </p>
              <p>
                <b>Service Providers or Subcontractors: </b>Authorized personnel
                from our service providers or subcontractors who assist in
                delivering our services.
              </p>
              <p>
                <b>Private Sector Partners: </b>Including game developers and
                advertising partners who need the information to enhance user
                experience or for marketing purposes.
              </p>

              <p>
                <b>Government Authorities: </b>When we are legally obligated to
                disclose data in response to requests from government
                authorities.
              </p>
              <p>
                <b>Third Parties for Statistical Purposes: </b>Aggregated,
                non-identifiable user statistics shared with third parties for
                analysis and reporting.
              </p>
              <h3 className="font-bold text-2xl pt-1">Detailed Data Sharing Contexts</h3>
              <h4 className="font-bold text-xl pt-1">Authorized Zingames Employees</h4>
              <p>
                Employees within Zingames who have a legitimate need to access
                your data to fulfill their job responsibilities will have access
                to it. These employees are bound by strict confidentiality
                agreements.
              </p>
              <h4 className="font-bold text-xl pt-1">Service Providers and Subcontractors</h4>
              <p>
                We collaborate with various service providers and subcontractors
                who may need access to your data to perform services on our
                behalf. These providers are contractually obligated to handle
                your data securely and maintain confidentiality.
              </p>
              <h4 className="font-bold text-xl pt-1">Private Sector Partners</h4>
              <p>
                Our partnerships with game developers and advertising partners
                sometimes require sharing user data to improve game
                functionalities and deliver targeted advertisements. We ensure
                these partners adhere to stringent data protection standards.
              </p>
              <h4 className="font-bold text-xl pt-1">Government Authorities</h4>
              <p>
                In compliance with legal requirements, we may disclose your data
                to government authorities. This typically occurs under specific
                legal mandates such as court orders or regulatory
                investigations.
              </p>
              <h4 className="font-bold text-xl pt-1">Aggregated User Statistics</h4>
              <p>
                To understand our user base better and improve our services, we
                may share aggregated, non-identifiable user data with third
                parties. This data does not include any personal identifiers and
                is used purely for statistical analysis and research purposes.
              </p>

              <h4 className="font-bold text-xl pt-1">4. Ensuring Data Security</h4>
              <p>
                The protection of your privacy and data is of utmost importance.
                We have established comprehensive technical and organizational
                safeguards to protect personal data, ensuring the
                confidentiality, integrity, and availability of your
                information. All personnel at Zingames and those involved in
                data processing are obligated to adhere to strict
                confidentiality agreements.
              </p>
              <h4 className="font-bold text-xl pt-1">Security Measures and Protocols</h4>
              <p>
                Zingames has implemented a robust security framework that
                includes:
              </p>
              <p>
                <b>Advanced Technical Measures: </b>Utilization of encryption,
                firewalls, and secure access controls to protect data from
                unauthorized access and cyber threats.
              </p>
              <p>
                <b>Organizational Measures: </b>Regular training for employees
                on data protection and privacy policies, and strict access
                controls to ensure only authorized personnel can access
                sensitive data.
              </p>
              <h4 className="font-bold text-xl pt-1">Internal Documentation and Data Breach Procedures</h4>
              <p>
                We maintain comprehensive internal documentation that outlines
                our security measures and protocols. This includes:
              </p>
              <p>
                <b>Security Policies: </b>Detailed documentation of our security
                strategies, regularly reviewed and updated to address new
                threats.
              </p>
              <p>
                <b>Data Breach Response Plan: </b>A structured procedure to
                handle potential data breaches, which includes immediate
                containment measures, an investigation to understand the breach,
                and steps to mitigate any damage.
              </p>
              <h4 className="font-bold text-xl pt-1">Breach Notification Process</h4>
              <p>
                In the event of a data breach, Zingames has a clear process in
                place to:
              </p>
              <p>
                <b>Notify Relevant Authorities: </b>We will inform regulatory
                bodies as required by law, detailing the nature and scope of the
                breach.
              </p>
              <p>
                <b>Inform Affected Individuals: </b>Individuals impacted by the
                breach will be promptly notified, with details on the nature of
                the breach, the data affected, and measures they can take to
                protect themselves.
              </p>

              <h3 className="font-bold text-2xl pt-1">5. International Data Transfers</h3>
              <p>
                We understand that parties involved in data processing may be
                located in various countries. Regardless of these locations, we
                prioritize the security and protection of your personal data.
              </p>
              <h4 className="font-bold text-xl pt-1">Cross-Border Data Transfers</h4>
              <p>
                <b>EU to Non-EU Transfers: </b>When transferring data from the
                European Union to countries outside the EU, we ensure compliance
                through adequacy decisions made by the European Commission or by
                implementing Standard Contractual Clauses (SCCs).
              </p>
              <p>
                <b>UK to Non-UK Transfers: </b>For data transfers from the
                United Kingdom, we adhere to adequacy regulations established by
                the UK government or utilize Standard Contractual Clauses with a
                UK Addendum to ensure compliance.
              </p>
              <h4 className="font-bold text-xl pt-1">Legal Bases for Specific Transfers</h4>
              <p>
                For certain specific data transfers, we may rely on additional
                legal bases, such as:
              </p>
              <p>
                <b>Explicit Consent: </b>We may ask for your explicit consent
                for transferring your data to a specific country.
              </p>
              <p>
                <b>Performance of a Contract: </b>Data may be transferred if
                necessary for the performance of a contract between you and
                Zingames.
              </p>
              <p>
                <b>Legal Claims: </b>Data transfers may occur for the
                establishment, exercise, or defense of legal claims.
              </p>
              <h3 className="font-bold text-2xl pt-1">6. Data Retention Policy</h3>
              <p>
                At Zingames, we ensure that your personal data is retained only
                for as long as necessary to fulfill the purposes for which it
                was collected, unless longer retention is required by legal
                obligations, ongoing proceedings, or the protection of freedom
                of expression.
              </p>
              <h4 className="font-bold text-xl pt-1">Retention Periods</h4>
              <p>
                <b>Standard Retention: </b>We retain your personal data only for
                the duration needed to achieve the specific processing purposes
                outlined in our policies. This period varies depending on the
                type of data and the context of its collection.
              </p>
              <p>
                <b>Early Deletion: </b>Your data may be deleted earlier than the
                standard retention period if you exercise certain privacy
                rights, such as the right to erasure, provided there are no
                overriding legal grounds for retaining the data.
              </p>
              <p>
                <b>Extended Retention: </b>In certain circumstances, we may need
                to retain your data for longer periods. This includes.
              </p>
              <p>
                <b>Legal Obligations: </b>Compliance with statutory requirements
                that mandate retaining data for a specified duration.
              </p>
              <p>
                <b>Ongoing Legal Proceedings: </b>Data may be retained as
                necessary for the duration of any ongoing legal procedures or
                for defending legal claims.
              </p>
              <p>
                <b>Freedom of Expression: </b>In some cases, data retention may
                be necessary to ensure the protection of freedom of expression
                and information.
              </p>
              <h4 className="font-bold text-xl pt-1">Managing Data Retention</h4>
              <p>
                To ensure compliance with these policies, Zingames maintains a
                detailed data retention schedule, regularly reviewed and updated
                in accordance with legal and regulatory changes. Our procedures
                include:
              </p>
              <p>
                <b>Periodic Reviews: </b>Regular audits to assess data retention
                needs and ensure that outdated or unnecessary data is securely
                deleted.
              </p>
              <p>
                <b>User Requests: </b>Mechanisms for users to request deletion
                of their data in accordance with their privacy rights, with
                prompt and secure handling of such requests.
              </p>
              <h3 className="font-bold text-2xl pt-1">7. Your Privacy Rights and How to Exercise Them</h3>
              <p>
                We are committed to ensuring you have full control over your
                personal data. You have several rights under data protection
                laws regarding your personal information.
              </p>
              <h4 className="font-bold text-xl pt-1">Overview of Your Privacy Rights</h4>
              <p>
                <b>Right of Access: </b>You can request detailed information
                about the personal data we process about you.
              </p>
              <p>
                <b>Right to Rectification: </b>If your personal data is
                inaccurate or incomplete, you can request corrections or
                updates.
              </p>
              <p>
                <b>Right to Erasure: </b>Under certain conditions, you can
                request the deletion of your personal data from our systems.
              </p>
              <p>
                <b>Right to Restrict Processing: </b>You can ask us to limit the
                processing of your personal data in specific circumstances.
              </p>
              <p>
                <b>Right to Data Portability: </b>You have the right to receive
                your personal data in a structured, commonly used format and
                transfer it to another data controller.
              </p>
              <p>
                <b>Right to Object: </b>You can object to the processing of your
                personal data when it is based on our legitimate interests.
              </p>
              <p>
                <b>Right to Withdraw Consent: </b>You can withdraw your consent
                for data processing at any time if consent is the basis for
                processing.
              </p>
              <p>
                <b>Right to Lodge a Complaint: </b>If you believe your data
                protection rights have been violated, you can file a complaint
                with a supervisory authority.
              </p>
              <h3 className="font-bold text-2xl pt-1">Exercising Your Privacy Rights</h3>
              <h4 className="font-bold text-xl pt-1">How to Submit a Request</h4>
              <p>
                To exercise any of your rights, please contact us using the
                information provided in Section 12. When making a request,
                ensure you provide sufficient information to verify your
                identity and specify which rights you wish to exercise. This
                helps us process your request efficiently.
              </p>
              <h4 className="font-bold text-xl pt-1">Identity Verification Process</h4>
              <p>
                To safeguard your privacy, we may need to verify your identity
                before processing your request. This could involve providing
                proof of identity or additional identifying information to
                confirm that the request is legitimate.
              </p>
              <h4 className="font-bold text-xl pt-1">Follow-Up on Requests</h4>
              <p>
                Once your identity is verified, we will process your request
                promptly. If additional time is required, or if we cannot
                fulfill your request, we will notify you with the reasons for
                the delay or refusal. Our goal is to respond to all requests
                within the legally required time frame, ensuring transparency
                and communication throughout the process.
              </p>

              <h3 className="font-bold text-2xl pt-1">8. Parties Responsible for Data Processing</h3>
              <p>
                Zingames is primarily responsible for the processing of your
                personal data. However, other entities involved in our services
                may also bear responsibility for specific processing activities.
              </p>
              <h4 className="font-bold text-xl pt-1">8.1 Game Developers</h4>
              <p>
                Game developers on the Zingames platform are required to remove
                all outgoing links and advertisements from their games. If the
                game developers collect personal data for their own purposes,
                Zingames is not liable for this data processing. We have
                established contractual safeguards with developers to ensure
                they comply with applicable data protection laws.
              </p>
              <h4 className="font-bold text-xl pt-1">8.2 Advertising Partners</h4>
              <p>
                We collaborate with advertising partners to deliver
                advertisements on our platform. These partners are responsible
                for processing your data in the context of advertising. We
                ensure your privacy is protected through appropriate contractual
                agreements. More details can be found in our Cookie Statement.
              </p>
              <h4 className="font-bold text-xl pt-1">8.3 Third-Party Content Providers</h4>
              <p>
                This Privacy Policy does not cover third-party content providers
                who are responsible for their own data processing activities.
                This includes third-party games available on Zingames.com. These
                providers are independently responsible for ensuring their
                compliance with relevant laws.
              </p>
              <h3 className="font-bold text-2xl pt-1">9. Updates to Our Privacy Policy</h3>
              <p>
                At Zingames, we may revise this Privacy Policy to incorporate
                new technologies, enhance our practices, or comply with legal
                changes. The most current version will always be accessible on
                Zingames.com.
              </p>

              <h3 className="font-bold text-2xl pt-1">10. Regional Privacy Addenda</h3>
              <h4 className="font-bold text-xl pt-1">10.1 Local Law Addendum (Canada)</h4>
              <p>
                If you access Zingames.com from Canada, please refer to the
                local law addenda specific to your region for additional privacy
                rights and protections applicable to Canadian residents.
              </p>
              <h4 className="font-bold text-xl pt-1">10.2 Local Law Addendum (European Union)</h4>
              <p>
                For residents of the European Union, the General Data Protection
                Regulation (GDPR) governs our processing of your personal data.
                In addition to the rights mentioned in this policy, you have the
                right to lodge a complaint with a supervisory authority in your
                EU member state if you believe your data protection rights have
                been infringed.
              </p>
              <h4 className="font-bold text-xl pt-1">10.3 Local Law Addendum (California, USA)</h4>
              <p>
                Residents of California are entitled to additional rights under
                the California Consumer Privacy Act (CCPA). These rights
                include:
              </p>
              <p>
                <b>Right to Know: </b>Request details about the personal
                information we collect and how we use and share it.
              </p>
              <p>
                <b>Right to Delete: </b>Request the deletion of your personal
                data, subject to certain exceptions.
              </p>
              <p>
                <b>Right to Opt-Out: </b>Opt-out of the sale of your personal
                data. To exercise these rights, please contact us directly.
              </p>

              <h3 className="font-bold text-2xl pt-1">11. Contact Us</h3>
              <p>
                For any inquiries regarding this Privacy Policy or our data
                practices, please contact us at <b>privacy@zingames.com</b> or
                through the following contact details:
              </p>
              <p>Address:</p>
              <p>Applyset Media</p>
              <p>
                88, Gokul park, Utran, Chorasi, Surat- 394105, Gujarat, India
              </p>
              <p>privacy@zingames.com</p>
              <p>
                If you prefer to communicate in another language, please notify
                us via email, and we will do our best to accommodate your
                request.
              </p>
            </div>
          </main>
        </div>
        
      </div>
    </>
  );
};

export default PrivacyPolicy;
