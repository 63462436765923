import React from "react";
import LeftSidebar from "../Components/LeftSidebar";
import Header from "../Components/Header";

const AboutUs = () => {
  return (
    <div className="bg-gray-900 min-h-screen flex flex-col">
      <Header />
      <div className="flex flex-1">
        <LeftSidebar />
        <main className="flex-1 p-8 pt-[90px]">
          <h1 className="text-4xl font-bold mb-6 text-gray-100">About Us</h1>
          <p className="mb-6 text-gray-300">
            ZinGames is a one-stop destination for a fun and engaging online
            gaming experience, brought to you by Applyset Media. We are a
            registered company based in Surat, Gujarat, India, and abide by all
            Indian laws and regulations.
          </p>
          <h3 className="text-2xl font-semibold mb-4 text-gray-100">
            Our Mission
          </h3>
          <p className="mb-6 text-gray-300">
            At ZinGames, our mission is to provide a curated selection of
            high-quality, browser-based games that are accessible to everyone,
            regardless of age, skill level, or device. We believe that games can
            be a powerful source of entertainment, education, and relaxation,
            and we strive to create a platform where everyone can find something
            they enjoy.
          </p>
          <h3 className="text-2xl font-semibold mb-4 text-gray-100">
            Our Commitment
          </h3>
          <ul className="list-disc list-inside mb-6 text-gray-300 pl-5">
            <li>
              <strong>Quality:</strong> We carefully select and curate our games
              to ensure they are of the highest quality, both in terms of
              gameplay and design.
            </li>
            <li>
              <strong>Variety:</strong> We offer a wide variety of games across
              different genres, from puzzle and strategy games to action and
              adventure games. There's something for everyone!
            </li>
            <li>
              <strong>Accessibility:</strong> Our games are all playable
              directly in your web browser, with no downloads or installations
              required. This makes it easy for anyone to jump in and start
              playing right away.
            </li>
            <li>
              <strong>Safety:</strong> We are committed to providing a safe and
              secure gaming environment for all our users. We have robust
              security measures in place to protect your information.
            </li>
            <li>
              <strong>Guidance and Information:</strong> We believe informed
              users are happy users. We strive to provide comprehensive
              information about each game, including gameplay instructions, age
              recommendations, and any other relevant details. Additionally, we
              will continue to develop resources to help users navigate the
              complexities of online gaming.
            </li>
          </ul>
          <h3 className="text-2xl font-semibold mb-4 text-gray-100">
            The Applyset Media Team
          </h3>
          <p className="mb-6 text-gray-300">
            ZinGames is a product of Applyset Media, a company passionate about
            creating engaging and entertaining online experiences. Our team is
            made up of talented individuals with a deep love for games and a
            commitment to providing exceptional service to our users.
          </p>
          <h3 className="text-2xl font-semibold mb-4 text-gray-100">
            Join the ZinGames Community!
          </h3>
          <p className="text-gray-300">
            We invite you to explore ZinGames, discover your new favorite game,
            and join our ever-growing community of gamers. If you have any
            questions or feedback, please don't hesitate to contact us. We're
            always happy to hear from you!
          </p>
        </main>
      </div>
    </div>
  );
};

export default AboutUs;
