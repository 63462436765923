import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MainContent = () => {
  const navigate = useNavigate();
  const [gamesDetails, setGamesDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const maxPages = 8;
  const gamesPerSection = 12;

  const handleGameClick = (game) => {
    const gameNameFormatted = game.namespace;
    navigate(`/game/${gameNameFormatted}`, {
      state: {
        gameLink: game.url,
        gameName: game.title,
        gameImage: game.image,
        gameDescription: game.description,
      },
    });
  };

  const fetchGamesData = async (page) => {
    if (page > maxPages || isFetching) return;
    setIsFetching(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}&page=${page}`
      );
      setGamesDetails((prevGames) => [...prevGames, ...response.data.items]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchGamesData(page);
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100 &&
      !isFetching
    ) {
      if (page < maxPages) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFetching]);

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      if (window.scrollY > 300) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () =>
      window.removeEventListener("scroll", handleScrollButtonVisibility);
  }, []);

  const numberOfSections = Math.ceil(gamesDetails.length / gamesPerSection);

  return (
    <div className="flex flex-col flex-grow mt-16 p-5">
      {/* Loader when fetching new data */}
      {isFetching && gamesDetails.length === 0 && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="w-16 h-16 border-4 border-gray-300 border-t-4 border-t-blue-600 rounded-full animate-spin"></div>
        </div>
      )}

      {/* Game Sections */}
      {Array.from({ length: numberOfSections }).map((_, sectionIndex) => {
        const startIdx = sectionIndex * gamesPerSection;
        const endIdx = startIdx + gamesPerSection;
        const gamesForSection = gamesDetails.slice(startIdx, endIdx);

        return (
          <React.Fragment key={sectionIndex}>
            <section className="flex flex-col md:flex-row gap-4 mb-8">
              <div className="w-full h-auto md:w-1/2 md:h-80 flex items-stretch">
                {gamesForSection[5] && (
                  <div
                    onClick={() => handleGameClick(gamesForSection[5])}
                    className="flex-grow bg-gray-700 p-4 md:p-6 rounded-md flex justify-center items-center cursor-pointer overflow-hidden"
                  >
                    <img
                      src={gamesForSection[5].banner_image}
                      className="w-full h-full object-cover"
                      alt={gamesForSection[5].name}
                    />
                  </div>
                )}
              </div>

              <div className="md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-4">
                {gamesForSection.slice(0, 6).map((game, index) => (
                  <div
                    key={index}
                    className="relative rounded-lg overflow-hidden cursor-pointer group"
                    onClick={() => handleGameClick(game)}
                  >
                    <img
                      src={game.banner_image}
                      alt={`Game ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 h-12 bg-black bg-opacity-60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white font-bold">{game.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="flex flex-col md:flex-row gap-4 mb-8">
              <div className="md:w-1/2 grid grid-cols-2 md:grid-cols-3 gap-4">
                {gamesForSection.slice(6, 12).map((game, index) => (
                  <div
                    key={index}
                    className="relative rounded-lg overflow-hidden cursor-pointer group"
                    onClick={() => handleGameClick(game)}
                  >
                    <img
                      src={game.banner_image}
                      alt={`Game ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 h-12 bg-black bg-opacity-60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white font-bold">{game.title}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full h-auto md:w-1/2 md:h-80 flex items-stretch">
                {gamesForSection[11] && (
                  <div
                    onClick={() => handleGameClick(gamesForSection[11])}
                    className="flex-grow bg-gray-700 p-4 md:p-6 rounded-lg cursor-pointer flex justify-center items-center overflow-hidden"
                  >
                    <img
                      src={gamesForSection[11].banner_image}
                      className="w-full h-full object-cover"
                      alt={gamesForSection[11].name}
                    />
                  </div>
                )}
              </div>
            </section>
          </React.Fragment>
        );
      })}

      {/* Scroll to top button */}
      {showScrollTopButton && (
        <button
          onClick={handleScrollTop}
          className="fixed bottom-12 right-8 bg-gray-800 text-white p-4 rounded-full shadow-lg"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default MainContent;
