import React from 'react';
import Header from '../Components/Header';
import LeftSidebar from '../Components/LeftSidebar';

const CookiePolicyPage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-gray-100">
      <Header />
      <div className="flex flex-1">
        <LeftSidebar />
        <main className="flex-1 p-8 pt-[90px]">
          <h1 className="text-3xl font-bold mb-6 text-gray-100">Cookie Policy</h1>
          <p className="mb-6">
            At ApplySet Media, we prioritize the privacy of all users visiting the ZinGames website. This Cookie Policy provides
            clear insights into ZinGames' privacy practices. It details the types of data we collect from our website visitors,
            especially those from the European Economic Area (EEA)*, and explains the use of cookies and similar technologies on
            zingames.com and associated domains (referred to as the <b>"ZinGames Website"</b>). Additionally, it offers background
            information on cookies and guidance on managing your cookie preferences.
          </p>
          <p className="mb-6">
            ApplySet Media Pvt. Ltd. ("ApplySet Media", "we", "our", or "us") is responsible for processing your data through the
            ZinGames Website (hereinafter referred to as “ZinGames”, ”Site”). For further information on how we manage your data and
            your privacy rights, please consult the ZinGames Privacy Policy.
          </p>
          <p className="mb-6">
            Please be aware that we may update this Cookie Statement from time to time. Please check here often for updates. Your
            continued use of the Site will be considered as your consent to the revised terms. We encourage you to review this
            Cookie Statement regularly to stay informed about the most recent version.
          </p>
          <p className="mb-6">
            Your region is determined based on your IP address. Using a VPN might prevent you from seeing the Cookie Policy relevant
            to your actual location.
          </p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Understanding Cookies</h2>
          <p className="mb-6">
            Cookies are small data files stored on your computer, tablet, smartphone, or other internet-enabled devices via your web
            browser. Websites place these cookies on your device through your browser, such as Microsoft Edge, Firefox, Safari,
            Google Chrome, Opera, Brave, and others.
          </p>
          <p className="mb-6">
            There are two main categories of cookies:
          </p>
          <ul className="list-disc list-inside mb-6 pl-5 text-gray-300">
            <li><b>First-Party Cookies:</b> These are cookies that ZinGames places on your device for our own purposes.</li>
            <li><b>Third-Party Cookies:</b> These cookies are placed by or on behalf of external parties (e.g., advertisers). ZinGames may also
              obtain cookie information from third parties when you visit other websites.
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Purpose of Cookies</h2>
          <p className="mb-6">
            Cookies enable ZinGames to recognize your device as you navigate through the ZinGames Website, remembering settings such
            as language preferences and game progress. Third-party cookies may also be used for similar purposes.
          </p>
          <p className="mb-6">For more information on cookies, visit <b>www.allaboutcookies.org.</b></p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Cookie Lifespan</h2>
          <p className="mb-6">Cookies have a defined validity period:</p>
          <ul className="list-disc list-inside mb-6 pl-5 text-gray-300">
            <li><b>Default Period:</b> Cookies are automatically removed by your browser after their validity period expires. ApplySet Media
              ensures they are removed after the specified time in the Cookie Table.
            </li>
            <li><b>Early Deletion/Refusal:</b> You can manually delete cookies before they expire or refuse cookies that require your consent.
              Refer to "Managing Cookies and Cookie Settings" for more information.
            </li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Similar Technologies</h2>
          <p className="mb-6">
            In addition to cookies, other technologies such as HTML5 Local Storage and Web Beacons (tracking pixels) can be used for
            similar purposes.
          </p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Types of Cookies We Use</h2>
          <p className="mb-6">ZinGames employs three types of cookies:</p>
          <ul className="list-disc list-inside mb-6 pl-5 text-gray-300">
            <li><b>Functional Cookies:</b> Essential for the proper functioning and navigation of the website. These cookies do not require your consent.</li>
            <li><b>Analytical Cookies:</b> Used to analyze website usage and improve our services. Consent is not always required unless it significantly impacts
              your privacy.
            </li>
            <li><b>Advertising Cookies:</b> Used to personalize your experience and deliver relevant ads. These cookies require your prior consent.</li>
          </ul>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Who Places Which Cookies?</h2>
          <p className="mb-6"><b>ZinGames Cookies:</b> Placed directly by or on behalf of ZinGames. This includes all functional cookies, some analytical cookies, and certain
            advertising cookies.
          </p>
          <p className="mb-6"><b>Developer Cookies:</b> Game developers may include cookies in their games for tracking purposes. While ZinGames is not responsible for these
            cookies, we ensure contractual safeguards are in place and provide a list of cookies used by developers.
          </p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Externally Hosted Games and Websites</h2>
          <p className="mb-6">This Cookie Policy applies only to cookies used on the ZinGames Website. We are not responsible for cookies placed by
            third parties when you visit other websites. We inform you via a banner if you access a third-party website from
            ZinGames.
          </p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">Managing Cookies and Cookie Settings</h2>
          <p className="mb-6"><b>Cookie Settings on ZinGames Website:</b> You can change your cookie settings when you visit our website for the first time. This allows you to control the use of
            data for personalization, performance measurement, and more.
          </p>
          <p className="mb-6"><b>Browser Settings:</b> Most browsers allow you to adjust settings to receive warnings before cookies are placed, refuse all cookies, or delete
            existing cookies. These settings need to be adjusted on each browser and device you use.
          </p>
          <p className="mb-6">Blocking or deleting certain cookies may affect the functionality of the ZinGames Website. While you may still see
            advertisements, they may be less relevant.
          </p>
          <h2 className="text-2xl font-semibold mb-4 text-gray-100">How to Change or Delete Cookie Settings in Your Browser</h2>
          <p className="mb-6">For convenience, here are links on how to change cookie settings in popular browsers:</p>
          <ul className="list-disc list-inside mb-6 pl-5 text-gray-300">
            <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" className="text-blue-400 hover:underline">Internet Explorer</a></li>
            <li><a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" className="text-blue-400 hover:underline">Microsoft Edge</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer" className="text-blue-400 hover:underline">Mozilla Firefox</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647" className="text-blue-400 hover:underline">Google Chrome</a></li>
            <li><a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac" className="text-blue-400 hover:underline">Safari</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" className="text-blue-400 hover:underline">Opera</a></li>
          </ul>
          <p className="mb-6">If you have further questions about our cookie policy, please contact us at <a href="mailto:privacy@zingames.com" className="text-blue-400 hover:underline">privacy@zingames.com</a>.</p>
        </main>
      </div>
    </div>
  );
};

export default CookiePolicyPage;
