import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import LeftSidebar from "../Components/LeftSidebar";

const CategoryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { category } = location.state || {};
  const [filteredGames, setFilteredGames] = useState([]);
  const [page, setPage] = useState(1); // Start fetching from page 1
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [maxPages] = useState(8); // Fetch 8 pages of data

  // Fetch games data for the given category and page
  const fetchGamesData = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}&page=${page}`
      );
      const games = response.data.items;
      const filteredCategoryGames = games.filter(
        (game) => game.category === category.toLowerCase()
      );
      setFilteredGames((prevGames) => [...prevGames, ...filteredCategoryGames]); // Append new data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Automatically fetch all pages up to the maxPages limit
  useEffect(() => {
    const fetchAllPages = async () => {
      setIsLoading(true); // Set loading state

      for (let i = 1; i <= maxPages; i++) {
        await fetchGamesData(i); // Fetch each page's data sequentially
        setPage(i); // Update current page
      }

      setIsLoading(false); // Reset loading state when done
    };

    if (category) {
      setFilteredGames([]); // Clear previous games when category changes
      fetchAllPages(); // Fetch games for the selected category
    }
  }, [category, maxPages]);

  // Handle game click to navigate to the game's page
  const handleGameClick = (game) => {
    const gameNameFormatted = game.namespace;
    navigate(`/game/${gameNameFormatted}`, {
      state: {
        gameLink: game.url,
        gameName: game.title,
        gameImage: game.image,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <Header />
      {/* Main container */}
      <div className="flex">
        <LeftSidebar />

        {/* Main content area */}
        <div className="flex-1 p-6 mt-[70px]">
          <h1 className="text-3xl font-bold mb-6">
            {category ? `${category} Games` : "Games"}
          </h1>

          {/* Games grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {filteredGames.length > 0 ? (
              filteredGames.map((game, index) => (
                <div
                  key={index}
                  className="relative bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow group overflow-hidden cursor-pointer"
                  onClick={() => handleGameClick(game)}
                >
                  <img
                    src={game.banner_image}
                    alt={game.title}
                    className="w-full h-full object-cover"
                  />
                  <h3 className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 text-xl font-semibold text-white opacity-0 group-hover:opacity-100 transition-opacity">
                    {game.title}
                  </h3>
                </div>
              ))
            ) : (
              <p>No games found for this category.</p>
            )}
          </div>

          {/* Show loader if fetching data */}
          {isLoading && (
            <div className="text-center mt-4">Loading games...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
