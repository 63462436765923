import React from "react";
import Logo from "../Assets/zin logo.svg";
import ZinGameText from "../Assets/Zin Games Text.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header
      style={{
        backgroundColor: "#2d3748",
        padding: "16px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left Side: Hamburger Icon, Logo, and Website Name */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            marginLeft: "50px",
          }}
        >
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              style={{ width: "48px", height: "48px" }}
            />
          </Link>
          <Link to="/">
            <img
              src={ZinGameText}
              alt="Zin Games Text"
              style={{ height: "48px" }}
            />
          </Link>
        </div>

        {/* Right Side: Search Field with Icon Inside */}
        {/* <div style={{ position: "relative" }}>
          <input
            type="text"
            placeholder="Search..."
            style={{
              padding: "8px 16px",
              paddingLeft: "40px",
              borderRadius: "8px",
              border: "1px solid #4a5568",
              backgroundColor: "#2d3748",
              color: "#a0aec0",
            }}
          />
          <div style={{ position: "absolute", top: "50%", left: "12px", transform: "translateY(-50%)", color: "#a0aec0" }}>
            <FaSearch />
          </div>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
