import React, { useState } from "react";
import Header from "../Components/Header";
import MainContent from "../Components/MainContent";
import Footer from "../Components/Footer";
import LeftSidebar from "../Components/LeftSidebar";

const HomePage = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      style={{
        backgroundColor: "#1a202c",
        color: "#edf2f7",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Header toggleSidebar={toggleSidebar} />
      <div style={{ display: "flex" }}>
        <LeftSidebar isOpen={isSidebarOpen} />
        <MainContent />
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
