import React from "react";
import LeftSidebar from "../Components/LeftSidebar";
import Header from "../Components/Header";
import { FaQuestionCircle } from "react-icons/fa";

const faqs = [
  {
    question: "What is ZinGames?",
    answer:
      "ZinGames, powered by ApplySet Media in India, is a premier online gaming platform offering a diverse array of free online games. Enjoy seamless, instant gameplay without downloads, logins, or interruptions. Whether at home or on the go, our games are accessible on desktop, tablets, and mobile devices. From exciting 2 Player Games and thrilling Car Games to advanced .io Games, ZinGames brings you categories packed with action and adventure games. Dive into popular titles each developed by global web developers committed to high-quality gaming experiences.",
  },
  {
    question: "How educational are the games on ZinGames?",
    answer:
      "ZinGames is not just about fun; it's also a hub for learning. With educational games spanning categories like Cool Math Games, Multiplication Games, and Thinking Games, we blend entertainment with educational content to create engaging learning experiences. Our games help enhance problem-solving skills, boost cognitive abilities, and encourage educational growth through interactive gameplay.",
  },
  {
    question: "How secure is playing on ZinGames?",
    answer:
      "User safety is paramount at ZinGames. We employ robust security measures and ensure that each game undergoes strict quality checks. ZinGames is designed to be a secure, browser-based platform that safeguards your privacy and online security, adhering strictly to internet safety protocols. Always remember that while we ensure safety on our platform, external links may lead to less secure environments.",
  },
  {
    question:
      "What should I do if I encounter inappropriate advertising on ZinGames?",
    answer:
      "At ZinGames, we maintain a strict policy against inappropriate ads. If you see any offensive or unsuitable advertising, please report it immediately to hello@zingames.com. Include any relevant screenshots or links, and we will take swift action to maintain our advertising standards.",
  },
  {
    question: "Are there any risks of viruses with ZinGames?",
    answer:
      "Absolutely not. ZinGames is designed to be a secure, browser-based platform where games are played directly in your browser—no downloads necessary. This approach significantly reduces the risk of viruses. However, always be vigilant against imitations of our site which may not provide the same security assurances.",
  },
  {
    question: "Is downloading required to play games on ZinGames?",
    answer:
      "ZinGames eliminates the hassle of downloads and installations. Access hundreds of games directly through your browser, ensuring a fast and secure gaming experience. This convenience means you can start playing immediately, free from the concerns of software installation.",
  },
  {
    question: "Is it true that all games on ZinGames are free?",
    answer:
      "Yes, ZinGames prides itself on providing an entirely free gaming experience. Through strategic partnerships with advertisers, we support our developers and maintain a free platform for all users without sacrificing game quality.",
  },
  {
    question: "How do I suggest a new game or feature for ZinGames?",
    answer:
      "We value community feedback! If you have a game or feature suggestion, please reach out to us at hello@zingames.com. Your input helps us to continually improve and expand our game offerings.",
  },
  {
    question: "What type of multiplayer games does ZinGames offer?",
    answer:
      "Explore a vast selection of multiplayer games on ZinGames, perfect for competing with friends or connecting with players around the world. From strategic 2 Player Games to action-packed Multiplayer Racing Games, there's always a way to join the fun and challenge others.",
  },
  {
    question: "How frequently does ZinGames update its game library?",
    answer:
      "We update our game selection regularly, adding new and exciting titles every week to keep our library fresh and engaging for all players.",
  },
  {
    question: "Can I submit feedback or a review for a game on ZinGames?",
    answer:
      "Yes, your feedback is crucial! Feel free to submit reviews and feedback on any game directly through our contact email, hello@zingames.com, or through the feedback feature on our game pages.",
  },
  {
    question: "Do ZinGames require internet access to play?",
    answer:
      "Yes, an internet connection is required to access our wide range of games. ZinGames leverages cloud storage to deliver content seamlessly and securely, ensuring that the latest updates are always available and that your game experience is consistent.",
  },
  {
    question: "What kind of support does ZinGames offer to players?",
    answer:
      "At ZinGames, we prioritize player satisfaction. Our dedicated support team is available to help resolve any issues or answer queries. Contact us anytime at hello@zingames.com, and we promise to assist you promptly.",
  },
  {
    question: "Are there any age restrictions for playing games on ZinGames?",
    answer:
      "ZinGames offers games that are suitable for all ages, but we particularly focus on providing a safe environment for younger players. Parental guidance is recommended for children under the age of 13 to ensure a safe and appropriate gaming experience.",
  },
  {
    question: "How does ZinGames handle user data and privacy?",
    answer:
      "User privacy is a cornerstone of our operations at ZinGames. We collect minimal personal data, ensuring that all information is handled with the highest security and confidentiality standards. Detailed information about our data handling practices can be found in our Privacy Policy available on our website.",
  },
  {
    question: "Can I play games on ZinGames with friends remotely?",
    answer:
      "Absolutely! ZinGames offers several multiplayer games that allow you to connect with friends remotely. Whether you are teaming up or competing against each other, our games provide a platform for fun and interaction from any location.",
  },
  {
    question: "What initiatives does ZinGames take for responsible gaming?",
    answer:
      "ZinGames is committed to promoting responsible gaming through a balanced approach. We offer tools and resources to help manage gaming habits and ensure that gaming remains a positive experience.",
  },
  {
    question: "How does ZinGames decide which new games to add?",
    answer:
      "Our game selection process is rigorous and community-driven. We consider player feedback, market trends, and creative innovation when choosing which games to add to our platform. This ensures a diverse and appealing game catalog that meets our high standards of quality and entertainment.",
  },
  {
    question: "Does ZinGames offer a mobile app?",
    answer:
      "Currently, ZinGames does not offer a mobile app; however, our website is fully optimized for mobile devices, allowing users to play games directly through their browser on any device.",
  },
  {
    question: "Are there opportunities for game developers with ZinGames?",
    answer:
      "Yes, we are always looking to collaborate with talented game developers. If you have a game you think fits ZinGames, Please feel free to contact us at Developers@zingames.com. We provide a platform that respects and promotes your creative work while connecting you with millions of gamers globally.",
  },
];

const FAQItem = ({ question, answer, isOpen, onClick }) => (
  <div
    className="bg-gray-800 rounded-lg mb-4 cursor-pointer transition-colors hover:bg-gray-700"
    onClick={onClick}
  >
    <div className="flex items-center p-4 border-b border-gray-700">
      <FaQuestionCircle className="text-yellow-400 text-2xl mr-4" />
      <h3 className="text-lg font-medium text-gray-100 flex-1">{question}</h3>
      <span className="text-gray-300 text-xl">{isOpen ? "-" : "+"}</span>
    </div>
    {isOpen && <p className="p-4 text-gray-200">{answer}</p>}
  </div>
);

const FAQs = () => {
  const [openIndex, setOpenIndex] = React.useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="flex min-h-screen bg-gray-900">
      <LeftSidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="p-8 flex-1 bg-gray-800 pt-[90px]">
          <h1 className="text-3xl font-bold mb-8 text-gray-100 text-center">
            Frequently Asked Questions
          </h1>
          <div className="max-w-3xl mx-auto">
            {faqs.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isOpen={openIndex === index}
                onClick={() => toggleFAQ(index)}
              />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default FAQs;
